import React, { useEffect, useState } from 'react';
import { fetchData } from '../common/utils';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import AuthProvider from './AuthProvider';
import TestGO from './TestGO';
import TestSoccer from './TestSoccer';
import TestMD from './TestMD';

const ConfigProvider = () => {

    const [config, setConfig] = useState([]);

    useEffect(() => {
        const getConfigData = async () => {
            try {
                const result = await fetchData('./config.json');
                setConfig(result);
            } catch (e) {
                console.log(e);
            }
        };

        getConfigData();
    }, []);


    const url = window.location.search;
    const params = new URLSearchParams(url);
    const page = params.get('page');
    let result;

    const renderApp = () => {
        switch (page) {
            case 'testSoccer':
                result = (<TestSoccer config={config} />);
                break;
            case 'testMD':
                result = (<TestMD config={config} />);
                break;
            case 'testGO':
                result = (<TestGO config={config} />);
                break;
            default:
                break;
        }

        return result;
    };

    return Object.keys(config).length > 0 &&

        <Auth0Provider
            domain={config['AUTH0_DOMAIN']}
            clientId={config['AUTH0_CLIENT_ID']}
            redirectUri={window.location.href}
            audience={'https://' + config['AUTH0_DOMAIN'] + '/api/v2/'}
            scope={config['AZURE_SCOPE_ROLE']}
        >
            <AuthProvider config={config}>
                <Router>
                    {renderApp()}
                </Router>
            </AuthProvider>
        </Auth0Provider>

        ;
};


export default ConfigProvider;
