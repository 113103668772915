export const fetchData = async (url: string) => {
    try {
        const data = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        return await data.json();
    } catch (e) {
        return null;
    }
};