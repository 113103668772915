import React, { useState } from 'react';
import { Button, CircularProgress, Grid, /* Paper, */ TextField, Typography } from "@material-ui/core";
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';

/* Green/red theme for buttons */
const theme = createTheme({
    palette: {
        primary: green,
        secondary: red,
    },
});

const TestSoccer = ({ config }) => {

    const [disabledStart, setDisabledStart] = useState(true);
    const [eventsCounts, setEventsCounts] = useState(0);
    const [commandsCount, setCommandsCount] = useState(0);
    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(0);
    const [average, setAverage] = useState(0);
    const [minMemory, setMinMemory] = useState(0);
    const [maxMemory, setMaxMemory] = useState(0);
    const [averageMemory, setAverageMemory] = useState(0);
    const [sentCommands, setSentCommands] = useState(0);
    const [messagesReceived, setMessagesReceived] = useState(0);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [duration, setDuration] = useState(0);
    const [statusBar, setStatusBar] = useState({
        text: 'In progress',
        display: 'none',
        color: 'black',
        loadingScreen: 'none'
    });

    const [socketStatus, setSocketStatus] = useState({
        text: 'In progress',
        display: 'none',
        color: 'black',
        loadingScreen: 'none'
    });

    const calculateDuration = (start, end) => {
        let startDate = new Date(start);
        let endDate = new Date(end);
        let timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
        let timeDiffInSecond = Math.ceil(timeDiff / 1000);

        return timeDiffInSecond;
    }

    /* Update inputField hooks */
    const handleInputChange = (event) => {
        switch (event.target.name) {
            case 'eventsCounts':
                if (event.target.value <= 400) {
                    setEventsCounts(Number(event.target.value));
                }
                break;
            case 'commandsCount':
                if (event.target.value <= 1000) {
                    setCommandsCount(Number(event.target.value));
                }
                break;
            default:
                break;
        }
    }

    const createPostRequest = () => {

        const postParams = JSON.stringify({
            maxCommands: commandsCount,
            maxConnections: eventsCounts
        });

        let testUrl = config.REACT_APP_LOAD_TEST_BE_URL + 'soccer';

        console.log(testUrl)

        return fetch(testUrl, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: postParams
        })
        .then(function (response) {
            if (response.status === 200){
                setTimeout(() => {
                    getResults()
                }, 1500);
            } else {
                setStatusBar({ display: 'block', text: 'Sorry, there is another test running at the momment!!! Try again later!', color: 'lightgreen', loadingScreen: 'none' });
                setDisabledStart(true);
            }
        })
    };

    const getResults = () => {
        let testUrl = config.REACT_APP_LOAD_TEST_BE_URL + 'results';

        return fetch(testUrl, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                if (myJson.connection_counter < eventsCounts) {
                    setSocketStatus({ text: 'Connecting sockets : ' + myJson.connection_counter, display: 'block', color: 'black', loadingScreen: 'block' });
                } else {
                    setSocketStatus({ text: 'Connected sockets : ' + myJson.connection_counter, display: 'block', color: 'black', loadingScreen: 'none' });
                }

                setStatusBar({ display: 'block', text: 'Received commands : ' + myJson.all_responses, color: 'lightgreen', loadingScreen: 'block' })

                if (myJson.all_responses < commandsCount * eventsCounts) {
                    setTimeout(() => {
                        getResults();
                    }, 100);
                } else {
                    setStatusBar({ display: 'block', text: 'Test Completed!', color: 'green', loadingScreen: 'none' });
                    setMaxValue(myJson.max_total_time);
                    setMinValue(myJson.min_total_time);
                    setMinMemory(myJson.min_memory);
                    setMaxMemory(myJson.max_memory);
                    setAverage(myJson.average_time);
                    setAverageMemory(myJson.average_memory);
                    setSentCommands(myJson.all_commands);
                    setMessagesReceived(myJson.all_responses);
                    setEndTime(myJson.endDate);
                    setStartTime(myJson.startDate)
                    setDuration(calculateDuration(myJson.startDate, myJson.endDate));
                    setDisabledStart(true);
                }
            });
    }

    return (
        <div className="TestSoccer" >

            <Grid container>
                <Grid item xs>
                    <Typography
                        color='primary'
                        align="center"
                        variant='h2'
                    >
                        Load Test Soccer Back-End
                    </Typography>
                </Grid>
            </Grid>

            <div className="filterTabDiv" style={styles.filterTabDiv}>
                <Grid className="filterTab" container alignItems="center" justifyContent="center" spacing={2}>
                    <Grid item xs={2} sm={2} className='1'>
                        <TextField
                            className='2'
                            label="Number of events"
                            value={eventsCounts || ""}
                            type="number"
                            variant="outlined"
                            size="small"
                            onChange={handleInputChange}
                            name="eventsCounts"
                            data-cy="number-of-events"
                            required
                            InputProps={{ inputProps: { min: 0, max: 400, } }}
                            style={styles.filtersWidth}
                        />
                    </Grid>

                    <Grid item xs={2} sm={2}>
                        <TextField
                            label="Number of commands"
                            value={commandsCount || ""}
                            type="number"
                            variant="outlined"
                            size="small"
                            onChange={handleInputChange}
                            name="commandsCount"
                            data-cy="number-of-commands"
                            required
                            InputProps={{ inputProps: { max: 1000, min: 0 } }}
                            style={styles.filtersWidth}
                        />
                    </Grid>

                    <Grid item /* xs={3} */>
                        <MuiThemeProvider theme={theme}>
                            <Button
                                disabled={!disabledStart || !eventsCounts || !commandsCount}
                                variant="contained"
                                color="primary"
                                size="small"
                                data-cy="start-load-test"
                                onClick={() => {
                                    /* All fields should be filled before creating sockets! */
                                    if (eventsCounts && commandsCount) {
                                        setDisabledStart(false);
                                        createPostRequest();
                                        setStatusBar({ display: 'block', text: 'Receiving commands...', color: 'lightgreen', loadingScreen: 'block' });
                                        setMaxValue(0);
                                        setMinValue(0);
                                        setMinMemory(0);
                                        setMaxMemory(0);
                                        setAverage(0);
                                        setAverageMemory(0);
                                        setSentCommands(0);
                                        setMessagesReceived(0);
                                        setStartTime('');
                                        setEndTime('');
                                        setDuration(0);
                                    }
                                }}>
                                Start
                            </Button>
                        </MuiThemeProvider>
                    </Grid>
                </Grid>
            </div>

            <div className="socketFields" style={{ display: socketStatus.display, color: socketStatus.color }}>
                <Grid className="socketFieldsStatusBar" container alignItems="center" justifyContent="center">
                    <Grid item xs={1} style={{ display: socketStatus.loadingScreen }}>
                        <Typography
                            //color='primary'
                            align="center"
                            variant='h3'
                        >
                            <CircularProgress />
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography
                            //color='primary'
                            align="center"
                            variant='h3'
                        >
                            {socketStatus.text}
                        </Typography>
                    </Grid>
                </Grid>
            </div>

            <div className="textFields" style={{ display: statusBar.display, color: statusBar.color }}>
                <Grid className="textFieldsStatusBar" container alignItems="center" justifyContent="center">
                    <Grid item xs={1} style={{ display: statusBar.loadingScreen }}>
                        <Typography
                            //color='primary'
                            align="center"
                            variant='h3'
                        >
                            <CircularProgress />
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography
                            //color='primary'
                            align="center"
                            variant='h3'
                        >
                            {statusBar.text}
                        </Typography>
                    </Grid>
                </Grid>
            </div>


            <div className="statsTitleField" style={{ paddingTop: '5%' }}>
                <Grid container>
                    <Grid item xs>
                        <Typography
                            color='primary'
                            align="center"
                            variant='h4'
                        >
                            Stats
                        </Typography>
                    </Grid>
                </Grid>
            </div>

            <div className="statsDiv" style={statusBar.text !== 'Test Completed!' ? styles.statsDivInProgress : styles.statsDivFinish}>
                <Grid className="statsRow1" container justifyContent="center" alignItems="center" spacing={3}>
                    <Grid item >
                        <TextField
                            // disabled
                            label="Sent Commands"
                            value={sentCommands || ""}
                            variant="outlined"
                            size="small"
                            name=""
                        />
                    </Grid>
                    <Grid item >
                        <TextField
                            // disabled
                            label="Received Commads"
                            value={messagesReceived || ""}
                            variant="outlined"
                            size="small"
                            name=""
                            style={statusBar.text !== 'Test Completed!' ? {} : styles.lightgreen}
                        />
                    </Grid>
                </Grid>

                <Grid className="statsRow2" container justifyContent="center" alignItems="center" spacing={3}>
                    <Grid item>
                        <TextField
                            // disabled
                            label="Start DateTime (yyyy-mm-dd)"
                            value={startTime !== '' ? startTime + ' UTC' : ""}
                            variant="outlined"
                            size="small"
                            name=""
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            // disabled
                            label="End DateTime (yyyy-mm-dd)"
                            value={endTime !== '' ? endTime + ' UTC' : ""}
                            variant="outlined"
                            size="small"
                            name=""
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            // disabled
                            label="Duration (hh:mm:ss)"
                            value={duration ? new Date(duration * 1000).toISOString().substr(11, 8) : ""}
                            variant="outlined"
                            size="small"
                            name=""
                        />
                    </Grid>
                </Grid>

                <Grid className="row2" container justifyContent="center" alignItems="center" spacing={3}
                    style={{ display: 'flex' }}> {/* show field only if we have less than 5 matches set to be loaded */}
                    <Grid item>
                        <TextField
                            // disabled
                            label="Min Process  Time (ms)"
                            value={minValue || ""}
                            variant="outlined"
                            size="small"
                            name=""
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            // disabled
                            label="Max Process Time (ms)"
                            value={maxValue || ""}
                            variant="outlined"
                            size="small"
                            name=""
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            // disabled
                            label="Average Process Time (ms)"
                            value={average || ""}
                            variant="outlined"
                            size="small"
                            name=""
                        />
                    </Grid>
                </Grid>

                <Grid className="statsRow5" container justifyContent="center" alignItems="center" spacing={3}>
                    <Grid item >
                        <TextField
                            // disabled
                            label="Min Memory usage"
                            value={minMemory ? minMemory + ' MiB' : ''}
                            variant="outlined"
                            size="small"
                            name=""
                        />
                    </Grid>
                    <Grid item >
                        <TextField
                            // disabled
                            label="Max Memory usage"
                            value={maxMemory ? maxMemory + ' MiB' : ''}
                            variant="outlined"
                            size="small"
                            name=""
                        />
                    </Grid>
                    <Grid item >
                        <TextField
                            // disabled
                            label="Average Memory usage"
                            value={averageMemory ? averageMemory + ' MiB' : ''}
                            variant="outlined"
                            size="small"
                            name=""
                        />
                    </Grid>
                </Grid>

            </div>

        </div >
    );
}

let styles = {
    filterTabDiv: {
        padding: '6%'
    },
    filtersWidth: {
        width: '85%'
    },
    statsDivInProgress: {
        padding: '1%',
        pointerEvents: 'none' as 'none',
    },
    statsDivFinish: {
        padding: '1%', pointerEvents: 'none' as 'none',
        width: '60%', border: '5px solid', borderRadius: '10px',
        margin: 'auto', backgroundColor: '#fdfbfb'
    },
    lightgreen: {
        backgroundColor: 'lightgreen'
    }
}

export default TestSoccer;
