import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

/* TO-DO impement postRedirectMessage functionality Vaso :D */
// const postRedirectMessage = (url: string): void => {
// 	const message = { 'event_id': 'redirect_message', data: { redirectUrl: url } };
// 	window.parent.postMessage(message, '*');
// };

const displayMessage = evt => {
    if (evt?.data?.data?.redirectUrl) {
        localStorage.setItem('parentUrl', evt?.data?.data?.redirectUrl);
    }
};

window.addEventListener('message', displayMessage, false);

const AuthProvider = ({ config, children }) => {
    const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

    useEffect(() => {
        (async function login() {

            if (!isLoading && !isAuthenticated) { // Redirect to auth0 pop up and save the full url
                await loginWithRedirect({ organization: config.AUTH0_ORGANIZATION_ID });
                localStorage.setItem('url-load-test', window.location.href);
            }

            /* Little hack to prevent from infinity loop */
            if (isAuthenticated) {
                const getUrl = localStorage.getItem('url-load-test');
                if (getUrl !== null) {
                    localStorage.removeItem('url-load-test');
                    window.location.assign(getUrl); // Take the full url after the success log in trought auth0
                }
            }

        })();
        //eslint-disable-next-line
    }, [isLoading, isAuthenticated]);

    return Object.keys(config).length > 0 && children// Return children if we have some props in config
};

export default AuthProvider;